// Here you can add other styles
table {
    th , td{
        border-left: 1px solid #d8dbe0;
        border-right: 1px solid #d8dbe0;
        border-bottom: 1px solid #d8dbe0;
        vertical-align: middle!important;
        text-align: center
    }
}

.c-switch-label.c-switch-lg .c-switch-slider {
    width: 75px;
    height: 28px;
}
.c-switch-label.c-switch-lg .c-switch-input:checked ~ .c-switch-slider::before {
    transform: translateX(45px);
}

// .c-switch-success .c-switch-input + .c-switch-slider {
//     background-color: #2eb85c;
// }

.c-switch-label.c-switch-lg .c-switch-slider::after {
    color: #fff;
}
.c-switch-slider {
    background-color: grey;
}
.c-switch-input:focus ~ .c-switch-slider {
    background-color: grey;
    box-shadow: none;
}
.swtich-pick {
    margin-right: 15px!important;
    padding-top: 10px;
}

button.myBtn{
    padding: 4px 8px !important;
    border-radius: 0px !important;
    margin-left: 0 !important;
    margin-bottom: 10px;
}

button.myBtnS{
    padding: 4px 8px !important;
    border-radius: 0px !important;
    margin-left: 0 !important;
    margin-bottom: 10px;
}

button.myBtnH{
    padding: 4px 8px !important;
    border-radius: 0px !important;
    margin-left: 0 !important;
    margin-bottom: 10px;
}

.c-switch-label.c-switch-lg .c-switch-slider::before {
    height: 21.6px !important;
}

.c-sidebar-nav-dropdown-toggle:after{
    transform: rotate(180deg) !important;
}

.c-sidebar-nav-dropdown.c-show>.c-sidebar-nav-dropdown-toggle:after{
    transform: rotate(270deg) !important;
}

// Spinner
.spinnerCustom{
    background: white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}